<div bsModal #createOrEditModal="bs-modal" class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="createOrEditModal" aria-hidden="true" 
    [config]="{ backdrop: 'static' }" (onShown)="onModalShown($event)">
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <form #pullPlanTaskForm="ngForm" novalidate (ngSubmit)="save()" autocomplete="off">
                <div class="modal-header">
                    <h2 class="modal-title">
                        <span *ngIf="id">{{ 'EditPullPlanTaskHeader' | localize }}</span>
                        <span *ngIf="!id">{{ 'CreateNewPullPlanTaskHeader' | localize }}</span>
                    </h2>
                    <button type="button" class="btn-modal-help" (click)="showHelp()" [title]="'Help' | localize" [attr.aria-label]="'Help' | localize">
                        <i class="ds-icon-default-circle-question"></i>
                    </button>
                    <button type="button" class="btn-close" (click)="close()" [title]="'Close' | localize" [attr.aria-label]="'Close' | localize" [disabled]="saving"></button>
                </div>
                <div class="modal-body pt-0 pb-3">
                    <input type="hidden" name="id" [(ngModel)]="id" />
                    <input type="hidden" name="taskPullPlanId" [(ngModel)]="taskPullPlanId" />

                    <div class="row mx-0 my-0">

                        <!-- container for edit form -->
                        <div class="{{ bsGridCols(8, 8, 12, 12, 12) }}">

                            <div class="row my-1">
                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                    <div class="form-group">
                                        <label class="form-label" for="PullPlanTask_TaskTitle">{{ 'TaskTitle' | localize }} *</label>
                                        <input type="text" id="PullPlanTask_TaskTitle" name="TaskTitle" class="form-control w-100"
                                            #PullPlanTask_TaskTitle="ngModel" [(ngModel)]="taskTitle" [attr.disabled]="taskLocked || readonly ? true : null"
                                            (ngModelChange)="onChangesMade('taskTitle')" minlength="1" maxlength="100" required />
                                        <validation-messages [formCtrl]="PullPlanTask_TaskTitle"></validation-messages>
                                    </div>
                                </div>

                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                    <tabset #staticTabs>
                                        <tab heading="{{ 'TaskTab_Details' | localize }}" aria-selected="true">
                                            <div class="row pt-6">
                                                <div class="{{ bsGridCols(8, 8, 8, 8, 7) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskProjectTeamId">{{ 'TaskResponsibility' | localize }}</label>
                                                        <select class="form-control w-100" id="PullPlanTask_TaskProjectTeamId" name="PullPlanTask_TaskProjectTeamId"
                                                            #PullPlanTask_TaskProjectTeamId [(ngModel)]="taskProjectTeamId" (change)="onChangesMade('taskProjectTeamId')">
                                                            <option [ngValue]="null">{{ 'TeamSelect' | localize }}</option>
                                                            <option *ngFor="let team of taskProjectTeams" [ngValue]="team.id">{{ team.teamTitle }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(4, 4, 4, 4, 5) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskDate">{{ 'TaskDate' | localize }}</label>
                                                        <app-date-picker #PullPlanTask_TaskDate id="PullPlanTask_TaskDate" name="TaskDate" 
                                                            [(ngModel)]="taskDate" (ngModelChange)="onChangesMade('taskDate')" [disabled]="taskLocked || readonly">
                                                        </app-date-picker>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskDate"></validation-messages>
                                                    </div>
                                                </div>
                
                                                <div class="{{ bsGridCols(3, 3, 3, 3, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskDuration">{{ 'TaskDuration' | localize }}</label>
                                                        <div class="input-group field-left">
                                                            <p-inputNumber id="PullPlanTask_TaskDuration" name="TaskDuration" inputStyleClass="form-control w-100"
                                                                #PullPlanTask_TaskDuration="ngModel" [(ngModel)]="taskDuration" [maxlength]="5"
                                                                mode="decimal" [maxFractionDigits]="2" [min]="0" [max]="24" [useGrouping]="false"
                                                                (ngModelChange)="onChangesMade('taskDuration')" [disabled]="taskLocked || readonly">
                                                            </p-inputNumber>
                                                            <span class="input-group-text">
                                                                <span>{{ 'TaskDuration_HoursShort' | localize }}</span>
                                                            </span>
                                                        </div>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskDuration"></validation-messages>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(3, 3, 3, 3, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskTeamSize">{{ 'TaskTeamSize' | localize }}</label>
                                                        <p-inputNumber id="PullPlanTask_TaskTeamSize" name="TaskTeamSize" class="d-block" inputStyleClass="form-control w-100"
                                                            #PullPlanTask_TaskTeamSize="ngModel" [(ngModel)]="taskTeamSize" [maxlength]="2"
                                                            mode="decimal" [maxFractionDigits]="0" [min]="1" [max]="99" [useGrouping]="false"
                                                            (ngModelChange)="onChangesMade('taskTeamSize')" [disabled]="taskLocked || readonly">
                                                        </p-inputNumber>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskTeamSize"></validation-messages>
                                                    </div>
                                                </div>
                                                
                                                <div class="{{ bsGridCols(3, 3, 3, 3, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskActualEffort">{{ 'TaskActualEffort' | localize }}</label>
                                                        <div class="input-group field-left">
                                                            <p-inputNumber id="PullPlanTask_TaskActualEffort" name="TaskActualEffort" inputStyleClass="form-control w-100"
                                                                #PullPlanTask_TaskActualEffort="ngModel" [(ngModel)]="taskActualEffort" [maxlength]="5"
                                                                mode="decimal" [maxFractionDigits]="2" [min]="0" [max]="24" [useGrouping]="false"
                                                                (ngModelChange)="onChangesMade('taskActualEffort')" [disabled]="taskLocked || readonly">
                                                            </p-inputNumber>
                                                            <span class="input-group-text">
                                                                <span>{{ 'TaskDuration_HoursShort' | localize }}</span>
                                                            </span>
                                                        </div>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskActualEffort"></validation-messages>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(3, 3, 3, 3, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskActualTeamSize">{{ 'TaskActualTeamSize' | localize }}</label>
                                                        <p-inputNumber id="PullPlanTask_TaskActualTeamSize" name="TaskActualTeamSize" class="d-block" inputStyleClass="form-control w-100"
                                                            #PullPlanTask_TaskActualTeamSize="ngModel" [(ngModel)]="taskActualTeamSize" [maxlength]="2"
                                                            mode="decimal" [maxFractionDigits]="0" [min]="1" [max]="99" [useGrouping]="false"
                                                            (ngModelChange)="onChangesMade('taskActualTeamSize')" [disabled]="taskLocked || readonly">
                                                        </p-inputNumber>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskActualTeamSize"></validation-messages>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskLocation">{{ 'TaskLocation' | localize }}</label>
                                                        <span [hidden]="!locationLocked" class="text-info float-end">{{ 'TaskLocationLockedToActivity' | localize }}</span>
                                                        <buildlocation-select-dropdown id="PullPlanTask_TaskLocationId" name="TaskLocationId"
                                                            #PullPlanTask_TaskLocationId="ngModel" [buildLocationList]="buildLocations" 
                                                            [(ngModel)]="taskLocationId" (selectionChanged)="onChangesMade('taskLocationId')" 
                                                            [disabled]="taskLocked || locationLocked || readonly">
                                                        </buildlocation-select-dropdown>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskLocationId"></validation-messages>
                                                    </div>
                                                </div>
                
                                                <div class="{{ bsGridCols(6, 6, 6, 6, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskWorkArea">{{ 'TaskWorkArea' | localize }}</label>
                                                        <input type="text" id="PullPlanTask_TaskWorkArea" name="TaskWorkArea" class="form-control w-100"
                                                            #PullPlanTask_TaskWorkArea="ngModel" [(ngModel)]="taskWorkArea" minlength="0" maxlength="100"
                                                            (ngModelChange)="onChangesMade('taskWorkArea')" [attr.disabled]="taskLocked || readonly ? true : null" />
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskWorkArea"></validation-messages>
                                                    </div>
                                                </div>
                
                                                <div class="{{ bsGridCols(7, 7, 7, 6, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskProgress">{{ 'TaskProgress' | localize }}</label>
                                                        <span class="form-label float-end">{{ taskProgress / 100 | percent }}</span>
                                                        <input type="range" id="PullPlanTask_TaskProgress" name="TaskProgress" class="form-range mt-2" 
                                                            min="0" max="100" step="1" #PullPlanTask_TaskProgress="ngModel" [attr.disabled]="taskLocked || readonly ? true : null"
                                                            [(ngModel)]="taskProgress" (ngModelChange)="onChangesMade('taskProgress')" />
                                                    </div>
                                                    <validation-messages [formCtrl]="PullPlanTask_TaskProgress"></validation-messages>
                                                </div>

                                                <div class="{{ bsGridCols(5, 5, 5, 6, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskStatusId">{{ 'TaskStatus' | localize }}</label>
                                                        <select class="form-control w-100" id="PullPlanTask_TaskStatusId" name="TaskStatusId"
                                                            [disabled]="taskStatusLocked || taskLocked || readonly ? true : null"
                                                            #PullPlanTask_TaskStatusId [(ngModel)]="taskStatusId" (change)="onChangesMade('taskStatusId')">
                                                            <option [ngValue]="TaskStatusEnum.ToDo">{{ 'TaskStatus_ToDo' | localize }}</option>
                                                            <option [ngValue]="TaskStatusEnum.InProgress">{{ 'TaskStatus_InProgress' | localize }}</option>
                                                            <option [ngValue]="TaskStatusEnum.AtRiskOrBlocked">{{ 'TaskStatus_AtRiskOrBlocked' | localize }}</option>
                                                            <option [ngValue]="TaskStatusEnum.Complete">{{ 'TaskStatus_Complete' | localize }}</option>
                                                        </select>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskStatusId"></validation-messages>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskNotes">{{ 'TaskNotes' | localize }}</label>
                                                        <div [class.readonly]="taskLocked || readonly">
                                                            <p-editor id="PullPlanTask_TaskNotes" name="TaskNotes" [style]="{'height':'95px'}"
                                                                [(ngModel)]="taskNotes" #PullPlanTask_TaskNotes="ngModel"
                                                                (onTextChange)="onChangesMade('taskNotes')" [readonly]="taskLocked || readonly"><!-- (onSelectionChange)="onTaskNoteSelectionChange($event)"> -->
                                                                <p-header>
                                                                    <span class="ql-formats">
                                                                        <button type="button" class="ql-bold" title="{{ 'QuillEditorBold' | localize }}"></button>
                                                                        <button type="button" class="ql-italic" title="{{ 'QuillEditorItalic' | localize }}"></button>
                                                                        <button type="button" class="ql-underline" title="{{ 'QuillEditorUnderline' | localize }}"></button>
                                                                    </span>
                                                                    <span class="ql-formats">
                                                                        <button type="button" class="ql-strike" title="{{ 'QuillEditorStrikethrough' | localize }}"></button>
                                                                        <button type="button" class="ql-script" value="sub" title="{{ 'QuillEditorSub' | localize }}"></button>
                                                                        <button type="button" class="ql-script" value="super" title="{{ 'QuillEditorSuper' | localize }}"></button>
                                                                    </span>
                                                                    <span class="ql-formats">
                                                                        <button type="button" class="ql-list" value="ordered" title="{{ 'QuillEditorOrdered' | localize }}"></button>
                                                                        <button type="button" class="ql-list" value="bullet" title="{{ 'QuillEditorBullet' | localize }}"></button>
                                                                    </span>
                                                                    <span class="ql-formats">
                                                                        <button type="button" class="ql-link" title="{{ 'QuillEditorLink' | localize }}"></button>
                                                                        <button type="button" class="ql-clean" title="{{ 'QuillEditorRemoveFormatting' | localize }}"></button>
                                                                            <!-- (click)="onTaskNoteEditorLinkClick($event)" -->
                                                                    </span>
                                                                </p-header>
                                                            </p-editor>
                                                        </div>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskNotes"></validation-messages>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>

                                        <tab heading="{{ 'TaskTab_Dependencies' | localize }}">
                                            <div class="row pt-6">
                                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskPullPlanActivityLinkId">{{ 'TaskPullPlanActivity' | localize }}</label>
                                                        <select class="form-control w-100" id="PullPlanTask_TaskPullPlanActivityLinkId" name="TaskPullPlanActivityLinkId"
                                                            #PullPlanTask_TaskPullPlanActivityLinkId [(ngModel)]="taskPullPlanActivityLinkId" 
                                                            (change)="onChangesMade('taskPullPlanActivityLinkId')" [disabled]="taskLocked || readonly">
                                                            <option [ngValue]="null">{{ 'TaskActivityNotLinked' | localize}}</option>
                                                            <option *ngFor="let activity of activitiesForLinking" [ngValue]="activity.id">{{ activity.activityName }}</option>
                                                        </select>
                                                        <validation-messages [formCtrl]="PullPlanTask_TaskPullPlanActivityLinkId"></validation-messages>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                                    <span class="form-label">{{ 'TaskPredecessors' | localize }}</span>
                                                    <div class="list-container mb-5">
                                                        <p-table #predecessorLinksTable [value]="taskPredecessors" dataKey="id" editMode="row" 
                                                            styleClass="p-datatable-sm" responsiveLayout="scroll">
                                                            <ng-template pTemplate="header" let-editing="editing">
                                                                <tr>
                                                                    <th><span>{{ 'Task' | localize }}</span></th>
                                                                    <th class="text-center w-25">
                                                                        <span style="display: inline-block;margin-top: 4px">{{ 'Actions' | localize}}</span>
                                                                        <button type="button" class="mini-tool-btn float-end" [disabled]="editingPredecessorLink" 
                                                                            (click)="addNewTaskPredecessorLink()" [hidden]="taskLocked || readonly">
                                                                            <i class="ds-icon-default-add"></i>
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-link let-editing="editing" let-ri="rowIndex">
                                                                <tr [pEditableRow]="link" 
                                                                    [class.link-deleted]="link.deleted" 
                                                                    [class.nohover]="editingPredecessorLink && link.id != editPredecessorLink?.id" 
                                                                    [class.editing]="editingPredecessorLink && link.id == editPredecessorLink?.id">
                                                                    <td>
                                                                        <p-cellEditor>
                                                                            <ng-template pTemplate="input">
                                                                                <select id="editPredecessorLink_linkedTask" name="editPredecessorLink_linkedTask" 
                                                                                    class="form-control compact w-100" [(ngModel)]="link.predecessorTaskId">
                                                                                    <ng-container *ngFor="let task of taskLookupList">
                                                                                        <option *ngIf="task.id != id" [ngValue]="task.id">{{ task.taskTitle }}</option>
                                                                                    </ng-container>
                                                                                </select>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="output">
                                                                                {{ link.predecessorTaskTitle }}
                                                                            </ng-template>
                                                                        </p-cellEditor>
                                                                    </td>
                                                                    <td class="w-25">
                                                                        <div class="float-end">
                                                                            <button type="button" class="mini-tool-btn btn-accept" pSaveEditableRow 
                                                                                (click)="acceptEditTaskPredecessorLink(link.id)" 
                                                                                [hidden]="!editing || taskLocked || readonly" [disabled]="editing && link.parentTaskId == 0">
                                                                                <i class="ds-icon-default-check"></i>    
                                                                            </button>
                                                                            <button type="button" class="mini-tool-btn btn-cancel" pCancelEditableRow 
                                                                                (click)="cancelEditTaskPredecessorLink(link.id)" 
                                                                                [hidden]="!editing || taskLocked || readonly">
                                                                                <i class="ds-icon-default-close"></i>    
                                                                            </button>
                                                                            <button type="button" class="mini-tool-btn btn-edit" pInitEditableRow 
                                                                                (click)="editTaskPredecessorLink(link.id)"
                                                                                [hidden]="editing || taskLocked || readonly" [disabled]="editingPredecessorLink || link.deleted">
                                                                                <i class="ds-icon-default-pen"></i>    
                                                                            </button>
                                                                            <button type="button" class="mini-tool-btn btn-delete" 
                                                                                (click)="deleteTaskPredecessorLink(link.id)"
                                                                                [hidden]="editing || taskLocked || readonly" [disabled]="editingPredecessorLink">
                                                                                <i class="ds-icon-default-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr class="nohover">
                                                                    <td colspan="2">
                                                                        <span class="empty-row">{{ 'TaskParentTaskNotLinked' | localize }}</span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                                    <span class="form-label">{{ 'TaskSuccessors' | localize }}</span>
                                                    <div class="list-container mb-5">
                                                        <p-table #successorLinksTable [value]="taskSuccessors" dataKey="id" editMode="row" 
                                                            styleClass="p-datatable-sm" responsiveLayout="scroll">
                                                            <ng-template pTemplate="header" let-editing="editing">
                                                                <tr>
                                                                    <th><span>{{ 'Task' | localize }}</span></th>
                                                                    <th class="text-center w-25">
                                                                        <span style="display: inline-block;margin-top: 4px">{{ 'Actions' | localize}}</span>
                                                                        <button type="button" class="mini-tool-btn float-end" [disabled]="editingSuccessorLink" 
                                                                            (click)="addNewTaskSuccessorLink()" [hidden]="taskLocked || readonly">
                                                                            <i class="ds-icon-default-add"></i>
                                                                        </button>
                                                                    </th>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-link let-editing="editing" let-ri="rowIndex">
                                                                <tr [pEditableRow]="link" 
                                                                    [class.link-deleted]="link.deleted" 
                                                                    [class.nohover]="editingSuccessorLink && link.id != editSuccessorLink?.id" 
                                                                    [class.editing]="editingSuccessorLink && link.id == editSuccessorLink?.id">
                                                                    <td>
                                                                        <p-cellEditor>
                                                                            <ng-template pTemplate="input">
                                                                                <select id="editSuccessorLink_linkedTask" name="editSuccessorLink_linkedTask" 
                                                                                    class="form-control compact w-100" [(ngModel)]="link.taskId">
                                                                                    <ng-container *ngFor="let task of taskLookupList">
                                                                                        <option *ngIf="task.id != id" [ngValue]="task.id">{{ task.taskTitle }}</option>
                                                                                    </ng-container>
                                                                                </select>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="output">
                                                                                {{ link.taskTitle }}
                                                                            </ng-template>
                                                                        </p-cellEditor>
                                                                    </td>
                                                                    <td class="w-25">
                                                                        <div class="float-end">
                                                                            <button type="button" class="mini-tool-btn btn-accept" pSaveEditableRow 
                                                                                (click)="acceptEditTaskSuccessorLink(link.id)" 
                                                                                [hidden]="!editing || taskLocked || readonly" [disabled]="editing && link.parentTaskId == 0">
                                                                                <i class="ds-icon-default-check"></i>    
                                                                            </button>
                                                                            <button type="button" class="mini-tool-btn btn-cancel" pCancelEditableRow 
                                                                                (click)="cancelEditTaskSuccessorLink(link.id)" 
                                                                                [hidden]="!editing || taskLocked || readonly">
                                                                                <i class="ds-icon-default-close"></i>    
                                                                            </button>
                                                                            <button type="button" class="mini-tool-btn btn-edit" pInitEditableRow 
                                                                                (click)="editTaskSuccessorLink(link.id)"
                                                                                [hidden]="editing || taskLocked || readonly" [disabled]="editingSuccessorLink || link.deleted">
                                                                                <i class="ds-icon-default-pen"></i>    
                                                                            </button>
                                                                            <button type="button" class="mini-tool-btn btn-delete" 
                                                                                (click)="deleteTaskSuccessorLink(link.id)"
                                                                                [hidden]="editing || taskLocked || readonly" [disabled]="editingSuccessorLink">
                                                                                <i class="ds-icon-default-trash"></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage">
                                                                <tr class="nohover">
                                                                    <td colspan="2">
                                                                        <span class="empty-row">{{ 'TaskParentTaskNotLinked' | localize }}</span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>
                                        
                                        <tab>
                                            <ng-template tabHeading>{{ 'TaskTab_RiskLog' | localize }}<span *ngIf="taskRiskFieldsPopulated" class="tab-risklog-icon"><i class="ds-icon-default-warning"></i></span></ng-template>
                                            <div class="row pt-6">
                                                <div class="{{ bsGridCols(7, 7, 7, 7, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskRiskCategoryId">{{ 'RiskCategory' | localize }} *</label>
                                                        <select class="form-control w-100" id="PullPlanTask_TaskRiskCategoryId" name="TaskRiskCategoryId"
                                                            #PullPlanTask_TaskRiskCategoryId [(ngModel)]="taskRiskCategoryId" (change)="onChangesMade('taskRiskCategoryId')"
                                                            [disabled]="(riskFieldsReadonly || riskFieldsDisabled || taskLocked || readonly) ? true : null">
                                                            <option [value]="0">{{ 'DropdownSelect' | localize }}</option>
                                                            <option *ngFor="let category of riskCategories" [ngValue]="category.id">{{ category.categoryName }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(5, 5, 5, 5, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskRiskIdentifiedDate">{{ 'TaskRiskIdentifiedDate' | localize }} *</label>
                                                        <app-date-picker #PullPlanTask_TaskRiskIdentifiedDate id="PullPlanTask_TaskRiskIdentifiedDate" name="TaskRiskIdentifiedDate" 
                                                            [(ngModel)]="taskRiskIdentifiedDate" (ngModelChange)="onChangesMade('taskRiskIdentifiedDate')" 
                                                            [disabled]="(riskFieldsReadonly || riskFieldsDisabled || taskLocked || readonly) ? true : null">
                                                        </app-date-picker>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(7, 7, 7, 7, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskRiskOwnerUserId">{{ 'TaskRiskOwner' | localize }} *</label>
                                                        <user-select-dropdown id="TaskRiskOwnerUserId" name="TaskRiskOwnerUserId" #taskRiskOwnerUserIdControl
                                                            [multiSelect]="false" [(userList)]="userList" [readonly]="riskFieldsReadonly" 
                                                            [disabled]="riskFieldsDisabled || taskLocked || readonly"
                                                            (selectionChanged)="onChangesMade('taskRiskOwnerUserId')">
                                                        </user-select-dropdown>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(5, 5, 5, 5, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskRiskResolveByDate">{{ 'TaskRiskResolveByDate' | localize }} *</label>
                                                        <app-date-picker #PullPlanTask_TaskRiskResolveByDate id="PullPlanTask_TaskRiskResolveByDate" name="TaskRiskResolveByDate" 
                                                            [(ngModel)]="taskRiskResolveByDate" (ngModelChange)="onChangesMade('taskRiskResolveByDate')" 
                                                            [disabled]="(riskFieldsReadonly || riskFieldsDisabled || taskLocked || readonly) ? true : null">
                                                        </app-date-picker>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(7, 7, 7, 7, 6) }}">
                                                    <div class="form-check form-switch mt-10">
                                                        <input type="checkbox" id="PullPlanTask_TaskRiskHasBeenResolved" name="TaskRiskHasBeenResolved" class="form-check-input" 
                                                            role="switch" [(ngModel)]="taskRiskHasBeenResolved" 
                                                            [disabled]="(riskFieldsReadonly || riskFieldsDisabled || taskLocked || readonly) ? true : null"
                                                            (change)="onChangesMade('taskRiskHasBeenResolved')" />
                                                        <label class="form-check-label noselect" for="PullPlanTask_TaskRiskHasBeenResolved">{{ 'TaskRiskHasBeenResolved' | localize }}</label>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(5, 5, 5, 5, 6) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskRiskResolvedDate">{{ 'TaskRiskResolvedDate' | localize }}</label>
                                                        <app-date-picker #PullPlanTask_TaskRiskResolvedDate id="PullPlanTask_TaskRiskResolvedDate" name="TaskRiskResolvedDate" 
                                                            [(ngModel)]="taskRiskResolvedDate" (ngModelChange)="onChangesMade('taskRiskResolvedDate')" 
                                                            [disabled]="(taskStatusId != TaskStatusEnum.AtRiskOrBlocked || !taskRiskHasBeenResolved || riskFieldsReadonly || riskFieldsDisabled || taskLocked || readonly) ? true : null">
                                                        </app-date-picker>
                                                    </div>
                                                </div>

                                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }}">
                                                    <div class="form-group">
                                                        <label class="form-label" for="PullPlanTask_TaskRiskNotes">{{ 'TaskRiskNotes' | localize }}</label>
                                                        <div class="p-editor-container" [class.readonly]="riskFieldsReadonly || riskFieldsDisabled">
                                                            <p-editor id="PullPlanTask_TaskRiskNotes" name="TaskRiskNotes" [style]="{'height':'95px'}"
                                                                [(ngModel)]="taskRiskNotes" #PullPlanTask_TaskRiskNotes="ngModel" (onTextChange)="onChangesMade('taskRiskNotes')"
                                                                [readonly]="riskFieldsReadonly || riskFieldsDisabled || taskLocked || readonly">
                                                                <p-header>
                                                                    <!-- <div *ngIf="!riskFieldsReadonly && !riskFieldsDisabled"> -->
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-bold" title="{{ 'QuillEditorBold' | localize }}"></button>
                                                                            <button type="button" class="ql-italic" title="{{ 'QuillEditorItalic' | localize }}"></button>
                                                                            <button type="button" class="ql-underline" title="{{ 'QuillEditorUnderline' | localize }}"></button>
                                                                        </span>
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-strike" title="{{ 'QuillEditorStrikethrough' | localize }}"></button>
                                                                            <button type="button" class="ql-script" value="sub" title="{{ 'QuillEditorSub' | localize }}"></button>
                                                                            <button type="button" class="ql-script" value="super" title="{{ 'QuillEditorSuper' | localize }}"></button>
                                                                        </span>
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-list" value="ordered" title="{{ 'QuillEditorOrdered' | localize }}"></button>
                                                                            <button type="button" class="ql-list" value="bullet" title="{{ 'QuillEditorBullet' | localize }}"></button>
                                                                        </span>
                                                                        <span class="ql-formats">
                                                                            <button type="button" class="ql-link" title="{{ 'QuillEditorLink' | localize }}"></button>
                                                                            <button type="button" class="ql-clean" title="{{ 'QuillEditorRemoveFormatting' | localize }}"></button>
                                                                        </span>
                                                                    <!-- </div> -->
                                                                </p-header>
                                                            </p-editor>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </tab>

                                        <tab heading="{{ 'TaskTab_ProjectTags' | localize }}">
                                            <div class="row pt-6">
                                                <div class="{{ bsGridCols(12, 12, 12, 12, 12) }} pb-0">
                                                    <span class="form-label">{{ 'ProjectTags' | localize }}</span>
                                                    <projecttag-link-manager #projectTagLinkManager height="230px" [readonly]="taskLocked || readonly"
                                                        [linkedProjectId]="projectId" (changesMade)="onChangesMade('linkedProjectTags')" 
                                                        [allProjectTags]="allProjectTags" [(linkedProjectTags)]="linkedProjectTags"
                                                        [showMultiSelectCount]="false">
                                                    </projecttag-link-manager>
                                                </div>
                                            </div>
                                        </tab>
                                    </tabset>
                                </div>
                            </div>
                        </div>

                        <!-- container for task preview -->
                        <div class="{{ bsGridCols(4, 4, 12, 12, 12) }} pb-0">
                            <span class="form-label">{{ 'TaskPreview' | localize }}</span>
                            <div>
                                <tasknote #taskPreview [taskId]="id" [taskDate]="taskDate"
                                    [taskDuration]="taskDuration" [taskLocationTitle]="taskLocationTitle" 
                                    [taskProgress]="taskProgress" [taskPullPlanId]="taskPullPlanId"
                                    [taskActualEffort]="taskActualEffort" [linkedActivityName]="linkedActivityName"
                                    [taskResponsibility]="taskResponsibility" [taskStatusId]="taskStatusId"
                                    [taskSwimlaneId]="taskSwimlaneId" [taskTeamSize]="taskTeamSize"
                                    [taskTheme]="taskTheme" [taskTitle]="taskTitle" [taskWorkArea]="taskWorkArea"
                                    [swimlaneTeamTitle]="swimlaneTeamTitle" [swimlaneTeamColour]="swimlaneTeamColour"
                                    [swimlaneTeamTextColour]="swimlaneTeamTextColour">
                                </tasknote>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="modal-footer pt-0">
                    <div class="d-inline-block">
                        <button type="button" class="ds outlined dark me-3 min-width" [disabled]="saving || !canSave" (click)="close()">
                            {{ 'Cancel' | localize }}
                        </button>
                        <button type="button" class="ds min-width" [disabled]="saving || editingPredecessorLink || editingSuccessorLink || !pullPlanTaskForm.form.valid || !canSave || (uneditedTaskLocked && taskLocked) || readonly" (click)="save()">
                            {{ 'Save' | localize }}
                        </button>
                        <div class="task-warnings">
                            <span class="text-red" *ngIf="taskStatusId == TaskStatusEnum.Complete && uneditedTaskStatusId != TaskStatusEnum.Complete && projectSettingTaskLockingEnabled">{{ 'PullPlanTaskLockedOnSaving' | localize }}</span>
                            <span class="text-red" *ngIf="readonly">{{ 'WhiteboardLockedTaskReadonly' | localize }}</span>
                        </div>
                    </div>
                    <div class="d-inline-block">
                        <div class="form-check form-switch me-5">
                            <input type="checkbox" id="PullPlanTask_TaskLocked" name="TaskLocked" class="form-check-input" role="switch" 
                                [disabled]="readonly || saving || !permission.isGranted('Pages.PullPlanTasks.LockUnlock') ? true : null" [(ngModel)]="taskLocked" />
                            <label class="form-check-label noselect" for="PullPlanTask_TaskLocked">{{ 'Locked' | localize }}</label>
                        </div>
                        <button type="button" *ngIf="!isNullOrUndefinedOrNaN(this.id) && entityHistoryEnabled" class="ds light me-5" (click)="showHistory()">
                            <i class="ds-icon-default-history me-2"></i>
                            {{ 'History' | localize }}
                        </button>
                        <button type="button" *ngIf="!isNullOrUndefinedOrNaN(this.id)" class="ds light" [disabled]="readonly || taskLocked || !permission.isGranted('Pages.PullPlanTasks.Delete')" (click)="deleteTask()">
                            <i class="ds-icon-default-trash me-2"></i>
                            {{ 'Delete' | localize }}
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>
<entityTypeHistoryModal #entityTypeHistoryModal></entityTypeHistoryModal>
