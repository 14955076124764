import { ChangeDetectorRef, Component, ElementRef, EventEmitter, forwardRef, Injector, Input, NgZone, OnChanges,
    OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CustomComponentBase } from '@shared/common/custom-component-base';
import { TaskSnapshotComparisonState, TaskStatus } from '@shared/PullPlanningEnums';
import { PullPlanTaskTrackedModel } from '@shared/PullPlanTaskTrackedModel';
import { PullPlanTaskDto } from '@shared/service-proxies/service-proxies';
import { DateTime } from 'luxon';
import { MenuItem } from 'primeng/api';
import { ColourHelper } from '@shared/helpers/ColourHelper';
import { Menu } from 'primeng/menu';

@Component({
    selector: 'tasknote',
    templateUrl: './tasknote.component.html',
    styleUrls: ['./tasknote.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => TaskNoteComponent),
            multi: true
        }
    ],
    host:{ "[attr.data-id]": "taskId" }
})
export class TaskNoteComponent extends CustomComponentBase implements OnInit, OnChanges {

    @ViewChild('tasknote', { read: ElementRef }) taskNoteElementRef: ElementRef;
    @ViewChild('tasknotebtn', { static: true }) tasknotebtn: HTMLButtonElement;
    @ViewChild('menu', { static: true }) menu: Menu;

    @Input() type: number = 0;
    @Input() taskId: number = 0;
    @Input() taskDate: DateTime;
    @Input() taskDuration: number = 0;
    //@Input() taskLocation: string = '';
    @Input() taskLocationId?: number | undefined;
    @Input() taskLocationTitle: string = '';
    @Input() taskProgress: number = 0;
    @Input() taskPullPlanId: number = 0;
    @Input() taskResponsibility: string = '';
    @Input() taskStatusId: number = 0;
    @Input() taskSwimlaneId?: number | undefined; //deprecated
    @Input() taskParentTaskId?: number | undefined;
    @Input() taskTeamSize?: number | undefined;
    @Input() taskTheme: string = '';
    @Input() taskTitle: string = '';
    @Input() taskWorkArea: string = '';
    @Input() taskColumnCellIndex: number = 0;
    @Input() taskLocked: boolean = false;
    @Input() taskActualEffort: number = 0;
    @Input() taskActualTeamSize?: number | undefined = 0;
    @Input() taskProjectTeamId?: number | undefined;
    // @Input() locationLocked: boolean = false;
    @Input() swimlaneTeamTitle: string = '';
    @Input() swimlaneTeamColour: string = '';
    @Input() swimlaneTeamTextColour: string = '';
    @Input() scale: number = 1;
    @Input() dependency: string = '';
    @Input() linkedActivityName: string = '';
    @Input() pullPlanTask: PullPlanTaskDto | PullPlanTaskTrackedModel;
    @Input() dragndrop: boolean = false;
    @Input() dragndroplinks: boolean = false;
    @Input() hoverClick: boolean = false;
    @Input() hover: boolean = false;
    @Input() source: string = '';
    @Input() editable: boolean = false;
    @Input() hide: boolean = false;
    @Input() allowGrips: boolean = false;
    @Input() selected: boolean = false;
    @Input() showSelectCheckbox: boolean = false;
    @Input() readonly: boolean = false;
    @Input() snapshotComparisonState: TaskSnapshotComparisonState = TaskSnapshotComparisonState.NoChange;
    @Input() snapshotComparisonProgress: number = 0;
    @Input() showSnapshotComparisonIcons: boolean = false;
    @Input() slide: boolean = false;
    @Input() inTaskList: boolean = false;

    @Output() DragEvent = new EventEmitter<ITaskDragEvent>();
    @Output() LinkDragEvent = new EventEmitter<ITaskDragEvent>();
    @Output() EditTask = new EventEmitter<number>();
    @Output() DuplicateTask = new EventEmitter<number>();
    @Output() RemoveTask = new EventEmitter<number>();
    @Output() DeleteTask = new EventEmitter<number>();
    @Output() CompleteTask = new EventEmitter<number>();
    @Output() MouseEvent = new EventEmitter<ITaskMouseEvent>();
    @Output() SelectTask = new EventEmitter<ITaskSelectData>();

    get strikethrough(): boolean {
        return (this.taskStatusId == 3);
    }

    get atRiskOrBlocked(): boolean {
        return (this.taskStatusId == 2);
    }

    get formattedDuration(): string {
        if (this.taskDuration && this.taskDuration > 0) {
            return `${this.taskDuration} ${this.l('TaskDuration_HoursShort')}`;
        } else {
            return '';
        }
    }

    get useLightStateIcon(): boolean {
        return (ColourHelper.getRecommendedTextColor(this.swimlaneTeamColour) == '#FFFFFF');
    }

    get taskProgressHidden(): boolean {
        return this.taskStatusId != TaskStatus.InProgress && this.taskStatusId != TaskStatus.AtRiskOrBlocked
            && !(this.showSnapshotComparisonIcons && this.zeroIfNull(this.snapshotComparisonProgress) != this.zeroIfNull(this.taskProgress) 
                && this.snapshotComparisonState != TaskSnapshotComparisonState.Added);
    }

    private zeroIfNull(value: number): number {
        return value || 0;
    }

    private isTrackedModel = (obj: any): obj is PullPlanTaskTrackedModel => { return true; }

    TaskStatusEnum: typeof TaskStatus = TaskStatus;
    TaskSnapshotComparisonStateEnum: typeof TaskSnapshotComparisonState = TaskSnapshotComparisonState;

    DEBUG = false;

    customStyles: string = ''; //this will be used to hold the positioning styles in the whiteboard
    menuitems: MenuItem[];

    constructor(
        private injector: Injector,
        private cdRef: ChangeDetectorRef
    ) {
        super(injector);
    }

    ngOnInit(): void {
        let context = this;
        this.uiDragService.dragStart$.subscribe(() => {
            if (context.menu != null && context.menu.visible) {
                context.menu.hide();
                context.cdRef.detectChanges();
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (!changes) { return; }
        if (changes.pullPlanTask) {
            this.refresh();
            let context = this;
            if (changes.pullPlanTask.currentValue && changes.pullPlanTask.currentValue.subscribe) {
                (changes.pullPlanTask.currentValue as PullPlanTaskTrackedModel).subscribe((e: string) => context.onModelChange(e));
            }
        }
        if (changes.taskProjectTeamId || changes.taskLocationId || changes.taskLocked || changes.taskStatusId || changes.readonly) {
            this.setMenuItems();
        }
    }

    private setMenuItems(): void {
        let subitems = [];

        if (this.readonly) {
            subitems.push({ label: this.l('WhiteboardViewTask'), icon: 'ds-icon-default-form', command: (event) => this.taskEditClick(), separator: false });
        } else {
            subitems.push({ label: this.l('WhiteboardEditTask'), icon: 'ds-icon-default-pen', command: (event) => this.taskEditClick(), separator: false });

            if (this.taskStatusId != TaskStatus.Complete && this.taskStatusId != TaskStatus.AtRiskOrBlocked && !this.taskLocked) {
                subitems.push({ label: this.l('WhiteboardTaskMarkAsComplete'), icon: 'ds-icon-default-check-square', command: (event) => this.taskComplete(), separator: false });
            }

            subitems.push({ label: this.l('WhiteboardDuplicateTask'), icon: 'ds-icon-default-stack', command: (event) => this.taskDuplicateClick(), separator: false });

            if (!this.taskLocked && !this.inTaskList) { //this.isNullOrUndefinedOrNaN(this.taskProjectTeamId) || !this.isNullOrUndefinedOrNaN(this.taskLocationId))) {
                subitems.push({ label: this.l('WhiteboardRemoveTask'), icon: 'ds-icon-default-download rotate-90', command: (event) => this.removeTaskClick(), separator: false });
            }

            if (!this.taskLocked) {
                subitems.push({ separator: true, label: '', icon: '', command: null });
                subitems.push({ label: this.l('WhiteboardDeleteTask'), icon: 'ds-icon-default-trash', command: (event) => this.taskDeleteClick(), separator: false });
            }
        }

        this.menuitems = [{ label: `<b>${this.taskTitle}</b>`, escape: false, items: subitems }];
    }

    private onModelChange(e: any) {
        if (this.DEBUG) { console.log('onModelChange:', e); }

        this.refresh();
    }

    public refresh(): void {
        if (!this.pullPlanTask) { return; }

        this.taskId = this.pullPlanTask.id;
        this.taskResponsibility = this.pullPlanTask.taskResponsibility;
        this.taskTeamSize = this.pullPlanTask.taskTeamSize;
        this.taskTitle = this.pullPlanTask.taskTitle;
        this.taskDate = this.pullPlanTask.taskDate;
        this.taskDuration = this.pullPlanTask.taskDuration;
        this.taskProgress = this.pullPlanTask.taskProgress;
        this.taskStatusId = this.pullPlanTask.taskStatusId;
        //this.taskLocation = this.pullPlanTask.taskLocation;
        this.taskLocationId = this.pullPlanTask.taskLocationId;
        this.taskLocationTitle = this.pullPlanTask.taskLocationTitle;
        this.taskWorkArea = this.pullPlanTask.taskWorkArea;
        this.taskLocked = this.pullPlanTask.taskLocked;
        this.taskSwimlaneId = this.pullPlanTask.taskSwimlaneId;
        this.taskParentTaskId = this.pullPlanTask.taskParentTaskId;
        this.taskColumnCellIndex = this.pullPlanTask.taskColumnCellIndex;
        this.taskTheme = this.pullPlanTask.taskTheme || 'yellow';
        this.taskActualEffort = this.pullPlanTask.taskActualEffort;
        this.taskActualTeamSize = this.pullPlanTask.taskActualTeamSize;
        this.taskProjectTeamId = this.pullPlanTask.taskProjectTeamId;
        // this.locationLocked = this.pullPlanTask.locationLocked;
        this.swimlaneTeamTitle = this.pullPlanTask.swimlaneTeamTitle;
        this.swimlaneTeamColour = this.pullPlanTask.swimlaneTeamColour;
        this.swimlaneTeamTextColour = this.pullPlanTask.swimlaneTeamTextColour;
        this.linkedActivityName = this.pullPlanTask.linkedActivityName;
        this.selected = this.pullPlanTask.selected;

        if (this.isTrackedModel) {
            this.snapshotComparisonState = (this.pullPlanTask as PullPlanTaskTrackedModel).snapshotComparisonState;
            this.snapshotComparisonProgress = (this.pullPlanTask as PullPlanTaskTrackedModel).snapshotComparisonProgress;
        }

        this.setMenuItems();
    }

    private getDataAsPullPlanTask(): PullPlanTaskDto {
        //PullPlanTaskDto has simple properties, whereas PullPlanTaskTrackedModel uses getters/setters;
        //when using JSON.stringify(), it will convert PullPlanTaskTrackedModel private variables
        //as it doesn't work with getters! (e.g. "taskTheme" will be output as "_taskTheme")

        let data: PullPlanTaskDto;
        if (typeof this.pullPlanTask != 'undefined' && this.pullPlanTask != null) {
            data = new PullPlanTaskDto();
            data.id = this.pullPlanTask.id;
            data.taskResponsibility = this.pullPlanTask.taskResponsibility;
            data.taskTeamSize = this.pullPlanTask.taskTeamSize;
            data.taskTitle = this.pullPlanTask.taskTitle;
            data.taskDate = this.pullPlanTask.taskDate;
            data.taskDuration = this.pullPlanTask.taskDuration;
            data.taskProgress = this.pullPlanTask.taskProgress;
            data.taskStatusId = this.pullPlanTask.taskStatusId;
            //data.taskLocation = this.pullPlanTask.taskLocation;
            data.taskLocationId = this.pullPlanTask.taskLocationId;
            data.taskLocationTitle = this.pullPlanTask.taskLocationTitle;
            data.taskWorkArea = this.pullPlanTask.taskWorkArea;
            data.taskLocked = this.pullPlanTask.taskLocked;
            data.taskSwimlaneId = this.pullPlanTask.taskSwimlaneId;
            data.taskParentTaskId = this.pullPlanTask.taskParentTaskId;
            data.taskTheme = this.pullPlanTask.taskTheme;
            data.taskActualEffort = this.pullPlanTask.taskActualEffort;
            data.taskActualTeamSize = this.pullPlanTask.taskActualTeamSize;
            data.taskProjectTeamId = this.pullPlanTask.taskProjectTeamId;
            data.taskColumnCellIndex = this.pullPlanTask.taskColumnCellIndex;
            data.swimlaneTeamTitle = this.pullPlanTask.swimlaneTeamTitle;
            data.swimlaneTeamColour = this.pullPlanTask.swimlaneTeamColour;
            data.swimlaneTeamTextColour = this.pullPlanTask.swimlaneTeamTextColour;
            data.linkedActivityName = this.pullPlanTask.linkedActivityName;
            // data.locationLocked = this.pullPlanTask.locationLocked;
        } else {
            data = new PullPlanTaskDto();
            data.id = this.taskId;
            data.taskResponsibility = this.taskResponsibility;
            data.taskTeamSize = this.taskTeamSize;
            data.taskTitle = this.taskTitle;
            data.taskDate = this.taskDate;
            data.taskDuration = this.taskDuration;
            data.taskProgress = this.taskProgress;
            data.taskStatusId = this.taskStatusId;
            //data.taskLocation = this.taskLocation;
            data.taskLocationId = this.taskLocationId;
            data.taskLocationTitle = this.taskLocationTitle;
            data.taskWorkArea = this.taskWorkArea;
            data.taskLocked = this.taskLocked;
            data.taskSwimlaneId = this.taskSwimlaneId;
            data.taskParentTaskId = this.taskParentTaskId;
            data.taskTheme = this.taskTheme;
            data.taskActualEffort = this.taskActualEffort;
            data.taskActualTeamSize = this.taskActualTeamSize;
            data.taskProjectTeamId = this.taskProjectTeamId;
            data.taskColumnCellIndex = this.taskColumnCellIndex;
            data.swimlaneTeamTitle = this.swimlaneTeamTitle;
            data.swimlaneTeamColour = this.swimlaneTeamColour;
            data.swimlaneTeamTextColour = this.swimlaneTeamTextColour;
            data.linkedActivityName = this.linkedActivityName;
            //data.locationLocked = this.locationLocked;
        }
        return data;
    }

    onDragEvents(type: string, event: DragEvent): void {
        if (!this.dragndrop) { return; }

        if (this.DEBUG) { console.log(`onDragEvents(type: ${type}) taskId: ${this.taskId}, event:`, event); }

        switch (type) {

            case 'dragover':
                if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                    event.preventDefault();
                }
                if (!this.hover) { this.hover = true; }
                return; //there's little we can do with the dragover event as we can't access the data!

            case 'dragenter':
                //event.preventDefault();
                // if (event.dataTransfer.effectAllowed != 'link') {
                //   event.dataTransfer.dropEffect = 'none';
                // }
                //if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                if (!this.hover) { this.hover = true; }
                //}
                break;

            case 'dragleave':
                // if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                if (this.hover) { this.hover = false; }
                // }
                break;

            case 'drop':
                //if (this.dragndroplinks && event.dataTransfer.effectAllowed == 'link') {
                if (this.hover) { this.hover = false; }
                //}
                event.preventDefault();
                break;

            default:
                if (type == 'dragstart') {
                    this.setDragNDropData(event.dataTransfer, `task`, this.getDataAsPullPlanTask());
                }
                break;
        }

        this.DragEvent.emit(new TaskDragEvent(type, this.getDataAsPullPlanTask(), event));
    }

    onGripDragEvents(type: string, event: DragEvent): void {
        if (!this.dragndrop || !this.allowGrips) { return; }
        if (type == 'dragover') { return; }

        event.stopImmediatePropagation();

        let task = this.getDataAsPullPlanTask();
        let griptype = (event.target as HTMLElement).getAttribute('data-griptype');

        switch (type) {
            // case 'dragover':
            //   return; //there's little we can do with the dragover event as we can't access the data!

            case 'dragstart':
                //event.preventDefault(); //this stops the default drag image appearing
                //event.stopImmediatePropagation(); //this stops the parent task drag event from also firing!
                this.setDragNDropData(event.dataTransfer, `${griptype}-link`, task);
                break;
        }

        this.LinkDragEvent.emit(new TaskDragEvent(griptype, task, event));
    }

    onMouseEvents(type: string, event: DragEvent): void {
        let task = this.getDataAsPullPlanTask();
        this.MouseEvent.emit(new TaskMouseEvent(type, task));
    }

    taskEditClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.EditTask.emit(id);
    }

    taskDuplicateClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.DuplicateTask.emit(id);
    }

    removeTaskClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.RemoveTask.emit(id)
    }

    taskDeleteClick(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.DeleteTask.emit(id)
    }

    taskComplete(): void {
        let id = this.taskId | this.pullPlanTask.id | 0;
        this.CompleteTask.emit(id);
    }

    // show(): void {
    //   this.hide = false;
    // }

    setCustomStyles(styles: string): void {
        this.customStyles = styles;
    }

    private setDragNDropData(dataTransfer: DataTransfer, type: string, task: PullPlanTaskDto): void {
        let data = new TaskDragData(type, this.source, task);
        let json = JSON.stringify(data);
        dataTransfer.setData('dragdata', json);
    }

    toggleSelection(ev: Event): void {
        this.selected = !this.selected;
        this.SelectTask.emit(new TaskSelectData(this.taskId, this.selected));
    }
}

export interface ITaskDragEvent {
    type: string;
    task: PullPlanTaskDto;
    event: DragEvent;
}

export class TaskDragEvent implements ITaskDragEvent {
    type: string;
    task: PullPlanTaskDto;
    event: DragEvent;

    constructor(type: string, task: PullPlanTaskDto, event: DragEvent) {
        this.type = type;
        this.task = task;
        this.event = event;
    }
}

export interface ITaskMouseEvent {
    type: string;
    task: PullPlanTaskDto;
}

export class TaskMouseEvent implements ITaskMouseEvent {
    type: string;
    task: PullPlanTaskDto;

    constructor(type: string, task: PullPlanTaskDto) {
        this.type = type;
        this.task = task;
    }
}

export interface ITaskDragData {
    type: string;
    source: string;
    task: PullPlanTaskDto;
}

export class TaskDragData implements ITaskDragData {
    type: string;
    source: string;
    task: PullPlanTaskDto;

    constructor(type?: string, source?: string, task?: PullPlanTaskDto) {
        this.type = type;
        this.source = source;
        this.task = task;
    }
}

export interface ITaskSelectData {
    id: number;
    selected: boolean;
}

export class TaskSelectData implements ITaskSelectData {
    id: number;
    selected: boolean;

    constructor(id: number, selected: boolean) {
        this.id = id;
        this.selected = selected;
    }
}
